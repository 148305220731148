<template>
  <div id="main">
    <div id="sidebar">
      <div class="logo">
        <img src="@/assets/owlting_logo_big.svg" width="143.75px" height="40px"/>
      </div>
      <div class="content">
        <div v-html="$t('sidebar.content')"></div>
      </div>
      <div class="footer">
        {{ $t('sidebar.footer') }}
      </div>
    </div>
    <div id="content" :class="routeName">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      routeName: ''
    }
  },
  mounted () {
    const name = this.$route.name.split('.')
    this.routeName = name.join('-')
  },
  watch: {
    $route (to, from) {
      const name = this.$route.name.split('.')
      this.routeName = name.join('-')
    }
  }
}
</script>
