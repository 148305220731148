import router from './router'
import NProgress from 'nprogress' // progress bar
import { getToken, getMode } from '@/utils/auth' // get token from cookie

const whiteList = ['/', '/redirect', '/redirect_update'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // determine whether the user has logged in
  const hasToken = getToken()

  const hasMode = getMode()

  if (hasToken && hasMode) {
    next()
    NProgress.done()
  } else {
    /* has no token */
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next({ path: '/' })
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  window.scrollTo(0, 0)
  NProgress.done()
})
