import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/components/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        name: 'home',
        path: '/',
        component: () => import('../views/Home.vue')
      },
      {
        name: 'owlting.404',
        path: '/404',
        component: () => import('../views/404.vue')
      },
      {
        name: 'customer.support',
        path: '/customer_support',
        component: () => import('@/views/CustomerSupport')
      },
      {
        name: 'customer.unused',
        path: '/customer_unused',
        component: () => import('@/views/CustomerUnused')
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../views/Redirect.vue')
  },
  {
    path: '/redirect_update',
    name: 'redirect.update',
    component: () => import('../views/RedirectUpdate.vue')
  },
  {
    path: '/verify',
    component: Layout,
    children: [
      {
        name: 'verify.index',
        path: '/verify',
        component: () => import('@/views/Verify')
      },
      {
        name: 'verify.success',
        path: '/verify/success',
        component: () => import('@/views/Success')
      },
      {
        name: 'verify.google.reset',
        path: '/verify/google/reset',
        component: () => import('@/views/GoogleVerifyReset')
      },
      {
        name: 'verify.google.reset.scan',
        path: '/verify/google/reset/scan',
        component: () => import('@/views/GoogleVerifyResetScan')
      },
      {
        name: 'verify.google.reset.bind',
        path: '/verify/google/reset/bind',
        component: () => import('@/views/GoogleVerifyResetBind')
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    children: [
      {
        name: 'setting.index',
        path: '/setting',
        component: () => import('@/views/Setting')
      },
      {
        name: 'setting.verify',
        path: '/setting/verify',
        component: () => import('@/views/SettingVerify')
      },
      {
        name: 'setting.alter',
        path: '/setting/alter',
        component: () => import('@/views/SettingAlter')
      },
      {
        name: 'setting.type.verify',
        path: '/setting/:type/verify',
        component: () => import('@/views/SettingTypeVerify')
      },
      {
        name: 'setting.success',
        path: '/setting/success',
        component: () => import('@/views/SettingSuccess')
      },
      {
        name: 'setting.google.flow',
        path: '/setting/google/flow',
        component: () => import('@/views/GoogleFlow')
      },
      {
        name: 'setting.google.step1',
        path: '/setting/google/step1',
        component: () => import('@/views/GoogleStep1')
      },
      {
        name: 'setting.google.step2',
        path: '/setting/google/step2',
        component: () => import('@/views/GoogleStep2')
      },
      {
        name: 'setting.google.step3',
        path: '/setting/google/step3',
        component: () => import('@/views/GoogleStep3')
      },
      {
        name: 'setting.google.reset',
        path: '/setting/google/reset',
        component: () => import('@/views/GoogleReset')
      },
      {
        name: 'setting.google.reset.scan',
        path: '/setting/google/reset/scan',
        component: () => import('@/views/GoogleResetScan')
      },
      {
        name: 'setting.google.reset.bind',
        path: '/setting/google/reset/bind',
        component: () => import('@/views/GoogleResetBind')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
