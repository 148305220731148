import Cookies from 'js-cookie'

const TokenKey = '2FA-Token'
const ModeKey = '2FA-Mode'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getMode () {
  return Cookies.get(ModeKey)
}

export function setMode (mode) {
  return Cookies.set(ModeKey, mode)
}

export function removeMode () {
  return Cookies.remove(ModeKey)
}
