<template>
  <div id="page">
    <div id="header">
      <div class="logo">
        <img src="@/assets/owlting_logo_big.svg" width="143.75px" height="40px"/>
      </div>
      <div class="lang-box">
        <div class="dropdown">
          <div @click.stop="language" class="dropbtn">
            <div class="earth-box mr-2">
              <i class="owl-earth"></i>
            </div>
            <div class="lang-block">
              <span>{{ map[$store.getters.lang] }}</span>
              <i class="owl-caret-bottom"></i>
            </div>
          </div>
          <div id="lang" @click.stop class="dropdown-content">
            <a v-for="(lang, index) in languages" :key="`lang${index}`" @click="changeLang(lang.value)">{{ lang.title }}</a>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      languages: [
        {
          title: '繁體中文',
          value: 'zh'
        },
        {
          title: 'English',
          value: 'en'
        }
      ],
      map: {
        zh: '繁體中文',
        en: 'English'
      }
    }
  },
  methods: {
    language (event) {
      document.getElementById('lang').classList.toggle('show')
      if (document.getElementById('lang').classList.value.includes('show')) {
        window.addEventListener('click', this.clickHandler)
      } else {
        window.removeEventListener('click', this.clickHandler)
      }
    },
    changeLang (lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('setLanguage', lang)
      document.getElementById('lang').classList.toggle('show')
      window.removeEventListener('click', this.clickHandler)
    },
    clickHandler () {
      document.getElementById('lang').classList.toggle('show')
      window.removeEventListener('click', this.clickHandler)
    }
  }
}
</script>
