import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, getToken, removeToken, setMode, getMode, removeMode } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(),
    mode: getMode(),
    language: localStorage.getItem('lang')
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_MODE: (state, mode) => {
      state.mode = mode
    },
    SET_LANGUAGE: (state, lang) => {
      localStorage.setItem('lang', lang)
      state.language = lang
    }
  },
  actions: {
    setJWT ({ commit }, jwt) {
      return new Promise(() => {
        setToken(jwt)
        commit('SET_TOKEN', jwt)
      })
    },
    removeJWT ({ commit }) {
      return new Promise(() => {
        commit('SET_TOKEN', '')
        removeToken()
      })
    },
    setMode ({ commit }, mode) {
      return new Promise(() => {
        setMode(mode)
        commit('SET_MODE', mode)
      })
    },
    removeMode ({ commit }) {
      return new Promise(() => {
        commit('SET_MODE', '')
        removeMode()
      })
    },
    setLanguage ({ commit }, languages) {
      commit('SET_LANGUAGE', languages)
    }
  },
  modules: {
  },
  getters: {
    token: (state) => {
      return state.token
    },
    mode: (state) => {
      return state.mode
    },
    lang: (state) => {
      return state.language
    }
  }
})
